import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { PlanModel } from '../../shared';
import { DvmService } from '../../shared/services/dvm.service';
import { SeatDataModel } from '../../pricing-management/shared/models/seat-data.model';
import { InventoryListModel } from '../shared/models/inventory-list.model';
import { PlanSeatCommentModel } from '../../pricing-management/shared';
import { rowsInfo } from './rows-info';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  seatsSelectedLengh = new Subject<number>();
  errorOnSaveSubject = new Subject<boolean>();
  seatAll = null;

  constructor(private http: HttpClient,
              private dvmService: DvmService) {
  }

  getPlanInventory(planId: number): Observable<any> { // TODO Create Model
    return this.http.get<any>(`/plan/${planId}/inventory/`);
  }

  getSectionInventory(planId: number, sectionId: string): Observable<any> { // TODO Create Model
    return this.http.get<any>(`/plan/${planId}/inventory/${sectionId}`);
  }

  getPlans(): Observable<Array<PlanModel>> {
    return this.http.get<Array<PlanModel>>(`/get_plans_readonly_crypto/`);
  }

  lockSeat(seatList: Array<any>, planId: number): Observable<any> {
    return this.http.post(`/plan/${planId}/seats/lock/`, { seats: seatList });
  }

  unlockSeats(seatList: Array<any>, planId: number): Observable<any> {
    return this.http.post(`/plan/${planId}/seats/unlock/`, { seats: seatList });
  }

  getSeatSelectedLength(): Observable<number> {
    return this.seatsSelectedLengh.asObservable();
  }

  setSeatSelectedLength(length: number): void {
    this.seatsSelectedLengh.next(length);
  }

  getPlanAvailability(plan: number): Observable<any> {
    return this.http.get<any>(`/plan/${plan}/availability/`);
  }

  getPlanAvailabilitySection(plan: number, section: string): Observable<any> {
    return this.http.get(`/plan/${plan}/availability/${section}/`);
  }

  setSeatsGroup(filteredAvailability: any): void {
    // Apply filters
    const arrayStatus = ['locked', 'on_hold', 'reserved', 'owned', 'internal_hold', 'nopricetype'];
    this.removeSeatsGroups();
    this.dvmService.viewer.addNodesToGroup(Object.keys(filteredAvailability.available), 'available');
    this.dvmService.viewer.addNodesToGroup(Object.keys(filteredAvailability.locked), 'locked');
    this.dvmService.viewer.addNodesToGroup(Object.keys(filteredAvailability.on_hold), 'on_hold');
    this.dvmService.viewer.addNodesToGroup(Object.keys(filteredAvailability.reserved), 'reserved');
    this.dvmService.viewer.addNodesToGroup(Object.keys(filteredAvailability.owned), 'owned');
    this.dvmService.viewer.addNodesToGroup(Object.keys(filteredAvailability.internal_hold), 'internal_hold');
    this.dvmService.viewer.addNodesToGroup(Object.keys(filteredAvailability.available_internal), 'available_internal');
    this.dvmService.viewer.addNodesToGroup(Object.keys(filteredAvailability.nopricetype), 'nopricetype');
    // status.seatmap.moduleRef.removeStatus(null, arrayStatus);
    // status.seatmap.moduleRef.addStatus(Object.keys(filteredAvailability.locked), 'locked');
    // status.seatmap.moduleRef.addStatus(Object.keys(filteredAvailability.on_hold), 'on_hold');
    // status.seatmap.moduleRef.addStatus(Object.keys(filteredAvailability.reserved), 'reserved');
    // status.seatmap.moduleRef.addStatus(Object.keys(filteredAvailability.owned), 'owned');
    // status.seatmap.moduleRef.addStatus(Object.keys(filteredAvailability.nopricetype), 'no-pricetype');
  }

  removeSeatsGroups(): void {
    const arrayStatus = ['locked', 'on_hold', 'reserved', 'owned', 'nopricetype'];
    this.dvmService.viewer.removeNodesFromGroup(
      this.dvmService.viewer.getNodesByGroups('seat', 'locked'),
      'locked'
    );
    this.dvmService.viewer.removeNodesFromGroup(
      this.dvmService.viewer.getNodesByGroups('seat', 'on_hold'),
      'on_hold'
    );
    this.dvmService.viewer.removeNodesFromGroup(
      this.dvmService.viewer.getNodesByGroups('seat', 'reserved'),
      'reserved'
    );
    this.dvmService.viewer.removeNodesFromGroup(
      this.dvmService.viewer.getNodesByGroups('seat', 'owned'),
      'owned'
    );
    this.dvmService.viewer.removeNodesFromGroup(
      this.dvmService.viewer.getNodesByGroups('seat', 'nopricetype'),
      'nopricetype'
    );
    this.dvmService.viewer.removeNodesFromGroup(
      this.dvmService.viewer.getNodesByGroups('seat', 'customerView'),
      'customerView'
    );
  }

  // setSeatsStatus(status: GeneralState, filteredAvailability: any): void {
  //   // Apply filters
  //   const arrayStatus = ['locked', 'on_hold', 'reserved', 'owned', 'nopricetype'];
  //   status.seatmap.moduleRef.removeStatus(null, arrayStatus);
  //   status.seatmap.moduleRef.addStatus(Object.keys(filteredAvailability.locked), 'locked');
  //   status.seatmap.moduleRef.addStatus(Object.keys(filteredAvailability.on_hold), 'on_hold');
  //   status.seatmap.moduleRef.addStatus(Object.keys(filteredAvailability.reserved), 'reserved');
  //   status.seatmap.moduleRef.addStatus(Object.keys(filteredAvailability.owned), 'owned');
  //   status.seatmap.moduleRef.addStatus(Object.keys(filteredAvailability.nopricetype), 'no-pricetype');
  // }
  //
  // removeSeatStatus(status: GeneralState): void {
  //   // Apply filters
  //   const arrayStatus = ['locked', 'on_hold', 'reserved', 'owned', 'no-pricetype'];
  //   status.seatmap.moduleRef.removeStatus(null, arrayStatus);
  // }
  // copy pasted from flow control service, could not insert here due to circular dependency
  // in flow control we inject inventory service
  private setSeatAll(availability: SeatDataModel[]) {
    this.seatAll = null;
    console.log('setSeatAll Func Availability ->', availability);
    for (const seat of availability) {
      if (seat.seat_row === 'all' && seat.price_type !== null) {
        console.log('yes');
        this.seatAll = seat;
      }
    }
  }

  // Se filtra por estado del asiento y se crea un objeto los diferentes status
  createAvailabilityHashmap(seatmaparray: any): any {
    this.setSeatAll(seatmaparray);
    const hashmap = {
      available: {}, on_hold: {}, reserved: {}, locked: {}, owned: {},
      available_internal: {}, internal_hold: {}, nopricetype: {}, seat4All: {}
    };
    for (const obj of seatmaparray) {

      // Available Filter
      if (obj.locked === true) {
        hashmap.locked[obj.id] = obj;
      }
      if (this.seatAll && !obj.seat_row.includes('all')) {
        hashmap.seat4All[obj.id] = obj;
        continue;
      }
      if (!obj.price_type || obj.price_type.prices.length === 0) {
        hashmap.nopricetype[obj.id] = obj;
        continue;
      }
      if (obj.final_event_status.length === 0) {
        hashmap.available[obj.id] = obj;
        continue;
      }
      const seatStatus = obj.final_event_status[0].status;
      switch (seatStatus) {
        case 'on_hold': {
          hashmap.on_hold[obj.id] = obj;
          break;
        }
        case 'owned': {
          hashmap.owned[obj.id] = obj;
          break;
        }
        case 'reserved': {
          hashmap.reserved[obj.id] = obj;
          break;
        }
        case 'internal_hold': {
          hashmap.internal_hold[obj.id] = obj;
          break;
        }
        case 'available_internal': {
          hashmap.available_internal[obj.id] = obj;
          break;
        }
      }
    }
    return hashmap;
  }

  // Se prepara un hashmap para insertar la disponibilidad
  getSeatmapAvailability(seatmaparray: any): any {
    const availability = {};
    for (const obj of seatmaparray) {
      availability[obj.id] = obj;
    }
    return availability;
  }

  getPlanCSV(planID: number): Observable<any> {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: 'text/csv'
      }),
      responseType: 'blob' as 'json',
    };
    return this.http.get(`/plan/${planID}/export`, HTTPOptions);
  }

  getPlanFromSectionCSV(planId: number, section: string): Observable<any> {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: 'text/csv'
      }),
      responseType: 'blob' as 'json',
    };
    return this.http.get(`/plan/${planId}/export/${section}`, HTTPOptions);
  }

  getSeatsBySection(params: any): Observable<Array<SeatDataModel>> {
    return this.http.get<Array<SeatDataModel>>(`/filtered_seat/`, { params });
  }

  // ENDPOINTS INVENTORY LIST
  getInventoryPlanList(planId: number): Observable<any> { // TODO List
    return this.http.get(`/plan/${planId}/list/`);
  }

  removeSeatFromList(sectionListToRemove: Array<string>, seatList: Array<InventoryListModel>): Array<InventoryListModel> {
    const newList = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < seatList.length; i++) {
      const seat = seatList[i];
      if (sectionListToRemove.indexOf(seat.seat_section) === -1) {
        newList.push(seat);
      }
    }
    return newList;
  }

  getSectionList(seatList: Array<InventoryListModel>): Array<string> {
    const sectionList = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < seatList.length; i++) {
      const seat = seatList[i];
      if (sectionList.indexOf(seat.seat_section) === -1) {
        sectionList.push(seat.seat_section);
      }
    }
    return sectionList;
  }

  changeSeatStatus(seatList: Array<any>, planId: number, status): Observable<any> {
    return this.http.post(`/plan/${planId}/seats/change_status/`, { seats: seatList, status });
  }

  createComment(comment: PlanSeatCommentModel): Observable<PlanSeatCommentModel> {
    return this.http.post<PlanSeatCommentModel>(`/plan_seat_comment/`, comment);
  }

  updateComment(comment: PlanSeatCommentModel): Observable<PlanSeatCommentModel> {
    return this.http.patch<PlanSeatCommentModel>(`/plan_seat_comment/${comment.id}`, comment);
  }

  deleteComment(commentId: number): Observable<any> {
    return this.http.delete(`/plan_seat_comment/${commentId}`);
  }

  private getRowsInfo() {
    return rowsInfo; // not a good way, fix when we have the api with this info
  }

  groupByAvailable(availabilityObject: Array<InventoryListModel>, numGroup) {
    numGroup = parseInt(numGroup, 10);
    const rowsInformation = this.getRowsInfo();
    // 1st order by row and section and create aux object by all sections and row with availability
    const availabilityObjectBySectionRow = {};
    const sectionsRowWithAvailability = {};
    let returnAvailability = [] as Array<InventoryListModel>;
    for (const seat of availabilityObject) {
      const section = 'S_' + seat.seat_section;
      if (!availabilityObjectBySectionRow[section]) {
        availabilityObjectBySectionRow[section] = {};
        sectionsRowWithAvailability[section] = {}; // if don't found in one either in the other
      }
      if (!availabilityObjectBySectionRow[section][seat.seat_row]) {
        availabilityObjectBySectionRow[section][seat.seat_row] = {};
        sectionsRowWithAvailability[section][seat.seat_row] = true; // if don't found in one either in the other;
      }
      availabilityObjectBySectionRow[section][seat.seat_row][seat.id] = seat;
    }
    // 2nd search available groups
    // for every row with availability
    for (const [section, rows] of Object.entries(sectionsRowWithAvailability)) {
      for (const row of Object.keys(rows)) {
        let rowInfo = [];
        // if we have no rows info (ie tables) continue looping
        try {
          rowInfo = rowsInformation[section][row];
        } catch (error) {
          continue;
        }
        // we iterate through every seat on rows info and see if available
        for (let i = 0; i < rowInfo.length; i++) {
          let found = 0;
          const seatStart = rowInfo[i];
          // if exists we try if the following elements of row info are also available
          if (availabilityObjectBySectionRow[section][row][seatStart]) {
            const nextSeatIds = rowInfo.slice(i + 1);
            found = 1;
            const returnAvailabilityGroup = [availabilityObjectBySectionRow[section][row][seatStart]];
            // for every element remaining of the row we see if is available and if
            for (const nextSeatId of nextSeatIds) {
              if (availabilityObjectBySectionRow[section][row][nextSeatId]) {
                found += 1;
                returnAvailabilityGroup.push(availabilityObjectBySectionRow[section][row][nextSeatId]);
              } else { // if not found finish looping
                break;
              }
            } // if we finished finding items we test if the number equals the desired group
            if (found === numGroup) {
              returnAvailability = [...returnAvailability, ...returnAvailabilityGroup];
            }
            // if found is greather than num group we delete this items for not appearing in incorrect groups
            // ie if seat 8 9 10 are available and numGroup =2 the seats 9 and 10 will be returned
            if (found > numGroup) {
              for (const seat of returnAvailabilityGroup) {
                const seatSection = 'S_' + seat.seat_section;
                delete availabilityObjectBySectionRow[seatSection][seat.seat_row][seat.id];
              }
            }
          }
        }
      }
    }
    // console.log(availabilityObjectBySectionRow);
    // console.log(sectionsRowWithAvailability);
    // console.log(returnAvailability);
    return returnAvailability;
  }
}
